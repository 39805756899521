@import url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-end;
  }
}
/* home */
.rs-picker-default .rs-picker-toggle.rs-btn {
  background-color: #039f79 !important;
}
.box-shadow-bottom{
  box-shadow: 0 10px 10px 0px rgb(241, 240, 240)
}
/* picked date align */
.bg-emerald{
  background-image:linear-gradient(180deg, #16C894 0%, #04A47C 100%);
}
.rs-picker-default .rs-picker-toggle {
  position: relative;
  z-index: 5;
  padding-right: 67px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  color: white !important;
  border: 1px solid #e5e5ea;
  padding-left: 44px;
}
/* calander align */
.rs-picker-toggle-caret {
  display: inline-block;
  margin-right: 240px;
  position: absolute;
  top: 8px;
  right: 12px;
  font-weight: normal;
  color: white !important;
}
/* ok button style */
.rs-picker-toolbar-right-btn-ok {
  text-align: center;
  cursor: pointer;
  outline: 0;
  border: none;
  padding: 8px 12px;
  font-size: 14px;
  border-radius: 30px;
  color: #fff;
  background-color: #3498ff;
  width: 100px;
}

/* range style */
.rs-picker-menu .rs-calendar .rs-calendar-table-cell-content {
  padding-left: 0;
  padding-right: 0;
  border-radius: 21px;
  display: inline-block;
}
/* sidebar */
#slide {
  position: absolute;
  left: -600px;
  -webkit-animation: slide 0.1s forwards;
  -webkit-animation-delay: 0.1s;
  animation: slide 0.5s forwards;
  animation-delay: 0.1s;
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

/* #slide-right {
  position: absolute;
  right:-600px;
  -webkit-animation: slide 0.1s forwards;
  -webkit-animation-delay: 0.1s;
  animation: slide 0.5s forwards;
  animation-delay: 0.1s;
}

@-webkit-keyframes slide-right {
  100% { right: 0; }
}

@keyframes slide-right {
  100% { right: 0; }
} */

#animate-left {
  position: relative;
  animation: animateleft 0.4s;
}
@keyframes animateleft {
  from {
    left: -600px;
    opacity: 2;
  }

  to {
    left: 0;
    opacity: 2;
  }
}
#animate-right {
  position: relative;
  animation: animateright 0.4s;
}
@keyframes animateright {
  from {
    right: -600px;
    opacity: 2;
  }

  to {
    right: 0;
    opacity: 2;
  }
}
#animate-right-back {
  position: relative;
  animation: animaterightback 0.6s;
}
@keyframes animaterightback {
  from {
    right: 0;
    opacity: 2;
  }

  to {
    right: -600px;
    opacity: 2;
  }
}
.animate-top {
  position: relative;
  animation: animatetop 0.45s;
}
@keyframes animatetop {
  from {
    top: -100px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}

.animate-top-xs {
  position: relative;
  animation: animatetopxs 0.6s;
}
@keyframes animatetopxs {
  from {
    top: -50px;
    opacity: 0;
  }

  to {
    top: 0;
    opacity: 1;
  }
}
.animate-bottom {
  position: relative;
  animation: animatebottom 0.3s;
}

.animate-bottom {
  position: relative;
  animation: animatebottom 0.3s;
}

@keyframes animatebottom {
  from {
    bottom: -100px;
    opacity: 0;
  }

  to {
    bottom: 0;
    opacity: 1;
  }
}
.radio-modal input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 20px;
  height: 20px;
  padding: 2px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
.radio-modal input[type="radio"]:checked {
  /* background-color: #26A69A; */
  background-color: var(--dynamic-color)!important;
}

/* endmodal */
/* product */
.product select:not([multiple]) {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-position: 9px center;
  background-repeat: no-repeat;
  background-image: url(./assets/images/icon_pos/filter.svg);
  padding-left: 2em;
}

#mySelect {
  border-radius: 0;
}
.search {
  background-position: 3px center;
  background-repeat: no-repeat;
  background-image: url(./assets/images/icon_pos/search.png);
}
/* product */

/* detailProduct */
.radio-detail input[type="radio"] {
  /* remove standard background appearance */
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  /* create custom radiobutton appearance */
  display: inline-block;
  width: 18px;
  height: 18px;
  padding: 3px;
  /* background-color only for content */
  background-clip: content-box;
  border: 2px solid #bbbbbb;
  background-color: #e7e6e7;
  border-radius: 50%;
}

/* appearance for checked radiobutton */
.radio-detail input[type="radio"]:checked {
  background-color: #62e54d;
}
/* endDetailProduct */
/* EditProduct */
.button-delete {
  margin-right: -25px !important;
}
/* endEditProduct */
.animate-none {
  -webkit-transition-property: none;
  -moz-transition-property: none;
  -o-transition-property: none;
  transition-property: none;
}
/* marketplace */
#cb {
  accent-color: #039F79;
}
input[type="search"]::-webkit-search-cancel-button {
  -webkit-appearance: none;
  height: 24px;
  width: 24px;
  margin-left: 0.4em;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%23777'><path d='M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z'/></svg>");
  cursor: pointer;
}
/* end marketplace */
